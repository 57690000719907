// src/NoticeContext.js
import React, { useRef, createContext, useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import { useSelector } from 'react-redux';

const socket = io(`https://vbott-07ca41b1b689.herokuapp.com`, {
    withCredentials: true, // Include credentials when making requests (if needed)
    transports: ['websocket', 'polling'], // Specify allowed transports
});

const BroadcastContext = createContext();

export const useNotice = () => {
    return useContext(BroadcastContext);
};

export const BroadcastProvider = ({ children }) => {

    const accessToken = useSelector(state => {
        if (state.user.userAuth) {
            return state.user.userAuth.accessToken;
        } else {
            return null;
        }
    });

    const userId = useSelector(state => {
        if (state.user.userAuth) {
            return state.user.userAuth.username;
        } else {
            return null;
        }
    });

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json', // Adjust as needed
    };

    // State to store the callback function
    const [messageCallback, setMessageCallback] = useState(null);
    const [notice, setNotice] = useState(null);

    // Callback function to handle the received message
    const handleReceivedMessage = (message) => {
        //console.log(`Message from ${message}`);
        toast.success(message, {
            position: 'top-center',
            autoClose: false,
        });

        if (setMessageReceivedCallback) {
            setMessageReceivedCallback(message);
        }
    };

    const showNotice = (message) => {
        setNotice(message);
    };

    const hideNotice = () => {
        setNotice(null);
    };

    // Function to set the callback function
    const setMessageReceivedCallback = (callback) => {
        setMessageCallback(callback);
    };

    useEffect(() => {
        handleRegister();

        socket.on('received message', ({ message }) => {
            // Call the handleReceivedMessage function
            handleReceivedMessage(message);
        });

        return () => {
            socket.off('received message');
        };
    }, []);

    const handleRegister = () => {
        // Register the client with the provided ID
        socket.emit('register', userId);
    };

    return (
        <BroadcastContext.Provider value={{ notice, showNotice, hideNotice, setMessageReceivedCallback }}>
            {children}
        </BroadcastContext.Provider>
    );
};

export default BroadcastProvider;
