import React, { useState, useEffect, useRef } from "react";
import { Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import Web3 from 'web3'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { create } from "@mui/material/styles/createTransitions";
import { useDispatch } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function Wallets() {

    const accessToken = useSelector(state => state.user.userAuth.accessToken);

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json', // Adjust as needed
    };

    const dispatch = useDispatch();

    const chainId = useSelector((state) => state.network.chainId);
    const [refreshing, setRefreshing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [feedback, setFeedback] = useState('');
    const [wallets, setWallets] = useState([]);
    const [rows, setRows] = useState([]);
    const web3Ref = useRef();
    const chainIdRef = useRef();
    const [pk, setPk] = useState("");
    const [selectedWalletAddress, setSelectedWalletAddress] = useState("");

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const blockScanners = {
        1: {
            network: "Mainnet",
            url: "https://etherscan.io",
        },
        56: {
            network: "BSC",
            url: "https://bscscan.com",
        },
        8453: {
            network: "Goerli",
            url: "https://basescan.org",
        },
        5: {
            network: "Goerli",
            url: "https://goerli.etherscan.io",
        },
    };

    const exchanges = {
        1: {
            rpc: "https://rpc.ankr.com/eth/a73fe89349cfbee583048758151ac46d80a869ef5ddfaf4543cd4e49a707a0cb"
        },
        56: {
            rpc: "https://rpc.ankr.com/bsc/a73fe89349cfbee583048758151ac46d80a869ef5ddfaf4543cd4e49a707a0cb",
        },
        8453: {
            rpc: "https://rpc.ankr.com/base/a73fe89349cfbee583048758151ac46d80a869ef5ddfaf4543cd4e49a707a0cb",
        },
        5: {
            rpc: "https://rpc.ankr.com/eth_goerli/a73fe89349cfbee583048758151ac46d80a869ef5ddfaf4543cd4e49a707a0cb",
        }
    };

    useEffect(() => {

        if (chainId) {
            //web3Ref.current = new Web3(new Web3.providers.HttpProvider(exchanges[chainId].rpc));
            web3Ref.current = new Web3(exchanges[chainId].rpc);
            chainIdRef.current = chainId;
            loadWallets();
        }

    }, [chainId])

    const loadWallets = async () => {

        setIsLoading(true);

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/getWallets`,{
                method:'GET',
                headers,
            });
            const response = await request.json();

            setFeedback(JSON.stringify(response));

            if (response.feedback == "success") {

                if (response.data.wallets.length > 0) {

                    setWallets(response.data.wallets);

                    //setDexes(response.data.dexInfo)

                    setRows(response.data.wallets.map((row) => ({ ...row, amount: '' })));
                    //console.log(rows)
                    await fetchBalances(response.data.wallets);

                } else {

                    setWallets(['']);

                }

            } else {

                setWallets([''])

            }

            setIsLoading(false);

        } catch (e) {
            console.log(e)
            setIsLoading(false);

            toast.error('An error occurred', {
                position: "top-center"
            })

        }

    }

    const fetchBalances = async (wallets) => {

        const promises = wallets.map(async (row) => {
            try {
                const balance = await getMultiBalance(`${row.wallet_address}`, [1]);
                return { ...row, balance }; // Combine the balance with the row data
            } catch (error) {
                console.error('Error fetching balance:', error);
                return row; // If an error occurs, keep the original row data
            }

        });

        const updatedWallets = await Promise.all(promises);
        setWallets(updatedWallets); // Update the component's state with the fetched balances
    };

    const getMultiBalance = async (address, chains) => {

        let totalBalance = 0;

        //for (let a = 0; a < chains.length; a++) {
        totalBalance = await getBalance(address, chainId);
        //}

        return `${totalBalance}`;

    }

    const getBalance = async (walletAddress, chainId) => {

        const web3 = web3Ref.current;

        // Replace with the Ethereum address you want to check
        const addressToCheck = `${walletAddress}`;

        try {

            const weiBalance = await web3.eth.getBalance(addressToCheck);

            // Convert the balance from wei to Ether
            const etherBalance = web3.utils.fromWei(weiBalance, 'ether');

            return etherBalance;

        } catch (e) {
            return 0;
        }

    }

    const loadPk = async (walletAddress) => {

        setIsLoading(true);

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/getPk/` + walletAddress,{
                method: "GET",
                headers,
            });
            const response = await request.json();

            if (response.feedback == "success") {

                setPk(response.data[0].private_key);

                setOpen(true);

            }

            setIsLoading(false);

        } catch (e) {
            console.log(e)
            setIsLoading(false);

            toast.error('An error occurred', {
                position: "top-center"
            })

        }

    }

    const createWallet = async () => {

        setIsLoading(true);

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/createWallet`,{
                method: "GET",
                headers,
            });

            const response = request.json();

            if (response.feedback == "success") {

            } else {

            }
            loadWallets();
            setIsLoading(false);

        } catch (e) {
            setIsLoading(false);
        }

    }

    return (
        <>
            <div style={{ marginBottom: 100, boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', padding: 10, margin: 20 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button variant="contained" onClick={() => createWallet()}>CREATE NEW WALLET </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" onClick={() => dispatch({type:"LOGOUT", user: null})}>LOGOUT</Button>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        {/* Column 1 content */}
                        <div>
                            Address
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        {/* Column 1 content */}
                        <div>
                            Balance
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        {/* Column 1 content */}
                        <div>
                            Action
                        </div>
                    </Grid>
                </Grid>
                <br />
                {wallets.map((row, index) => {
                    let url = "";
                    if (chainIdRef.current) {
                        url = blockScanners[chainIdRef.current].url + '/address/' + row.wallet_address;
                    }
                    return (
                        <Grid container spacing={2} key={index} style={{ marginBottom: 10 }}>
                            <Grid item xs={5}>
                                {/* Column 1 content */}
                                <div style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    <span style={{ fontSize: 12 }}>
                                        <a href={url} target="_blank">
                                            {row.wallet_address}
                                        </a>
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                {/* Column 1 content */}
                                <div style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    <span>
                                        {row.balance || 'Loading...'}
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                {/* Column 1 content */}
                                <div style={{ marginBottom: 5 }}>
                                    <Button variant="outlined" size="small" onClick={() => {
                                        loadPk(row.wallet_address)
                                    }}>
                                        PK
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    )

                })}

            </div>

            <div>

                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        Secured Zone
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            Do not share your private key with anyone!
                        </Typography>
                        <p style={{ maxWidth: '100%', overflow: 'auto', wordWrap: 'break-word' }}>
                            {pk}
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            CLOSE
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        </>
    )
}