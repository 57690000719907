import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {

    const navigate = useNavigate();

    const user = useSelector((e) => {
        return e.user.userAuth;
    })
    //console.log(user)
    // Normally, you'd get this value from your Redux store, Context API, etc.
    const isLoggedIn = false;

    if (user==null) {
        // Redirect to login page
        window.location.href = '/login';
        return;
    }

    return  children || <Outlet />;
};

export default ProtectedRoute;
