// src/App.js
import React from 'react';
import Navigation from './components/Navigation';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Use Routes instead of Switch
import Home from './screens/Home';
import Trades from './screens/Trades';
import Wallets from './screens/Wallets';
import Login from './screens/Login';
import ProtectedRoute from './components/ProtectedRoute'
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <Router>
      <div>
        <Navigation />
        <Routes>
        <Route path="/Login" element={<Login />} /> 
          <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} /> 
          <Route path="/trades" element={<ProtectedRoute><Trades /></ProtectedRoute>} />
          <Route path="/wallets" element={<ProtectedRoute><Wallets /></ProtectedRoute>} />
        </Routes>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
