import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useNotice } from "../components/Broadcaster";

export default function Trades() {

    const { setMessageReceivedCallback } = useNotice();

    const accessToken = useSelector(state => state.user.userAuth.accessToken);

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json', // Adjust as needed
    };

    const chainId = useSelector((state) => state.network.chainId);
    const [refreshing, setRefreshing] = useState(false);
    const [data, setData] = useState(['']);
    const [isLoading, setIsLoading] = useState(true);
    const [feedback, setFeedback] = useState('');

    const blockScanners = {
        1: {
            network: "Mainnet",
            url: "https://etherscan.io",
        },
        56: {
            network: "BSC MAINNET",
            url: "https://bscscan.com",
        },
        5: {
            network: "Goerli",
            url: "https://goerli.etherscan.io",
        },
    };

    const fetchData = async () => {

        setFeedback('')

        setIsLoading(true);

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/getTrades/` + chainId, {
                method: 'GET',
                headers,
            });

            const response = await request.json();

            setFeedback(JSON.stringify(response.data))

            if (response.feedback == "success") {

                if (response.data.length < 1) {

                    setData(['']);

                } else {

                    setData(response.data);

                }

            } else {

                setData([''])

            }

            setIsLoading(false);

        } catch (e) {
            console.log(e)
            setIsLoading(false);
        }

    };

    const handleRefresh = () => {
        setRefreshing(true);
        fetchData();
    };

    useEffect(() => {
        //console.log(setMessageReceivedCallback)
        // Set a callback function to handle received messages
        setMessageReceivedCallback((message) => {
            if (message) {
                console.log('Received message in MyComponent:', message);
                // Handle the received message in your component
                fetchData();
            }
        });

        return () => {
            setMessageReceivedCallback(null);
        };
    }, [setMessageReceivedCallback]);

    /*useEffect(() => {
        // Define the callback function to handle received messages
        const handleReceivedMessage = (message) => {
            if (message) {
                console.log('Received message in MyComponent:', message);
                // Handle the received message in your component
                fetchData();
            }
        };

        // Set the callback function when the component mounts
        setMessageReceivedCallback(handleReceivedMessage);

        // Clean up the callback when the component unmounts
        return () => {
            setMessageReceivedCallback(null);
        };
    }, [setMessageReceivedCallback]);*/

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <div style={{ marginBottom: 100, boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', padding: 10, margin: 20 }}>
                <Grid container spacing={2}>
                    <Grid item xs={1}>
                        {/* Column 1 content */}
                        <div>
                            ID
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        {/* Column 1 content */}
                        <div>
                            Transaction Hash
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        {/* Column 1 content */}
                        <div>
                            Wallet Address
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        {/* Column 1 content */}
                        <div>
                            Amount
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        {/* Column 1 content */}
                        <div>
                            Status
                        </div>
                    </Grid>
                </Grid>
                <br />
                {data.map((row, index) => {
                    let url = "";
                    if (row.chainId) {
                        url = blockScanners[row.chainId].url + '/tx/' + row.tx;
                    }
                    return (
                        <Grid container spacing={2} key={index} style={{ marginBottom: 10 }}>
                            <Grid item xs={1}>
                                {/* Column 1 content */}
                                <div style={{ marginBottom: 5 }}>
                                    {row.id}
                                </div>
                            </Grid>
                            <Grid item xs={5}>
                                {/* Column 1 content */}
                                <div style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    <span style={{ fontSize: 12 }}>
                                        <a href={url} target="_blank">
                                            {row.tx}
                                        </a>
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                {/* Column 1 content */}
                                <div style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    <span>
                                        {row.walletAddress}
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                {/* Column 1 content */}
                                <div style={{ marginBottom: 5 }}>
                                    {row.amount}
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                {/* Column 1 content */}
                                <div style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    {row.status}
                                </div>
                            </Grid>
                        </Grid>
                    )

                })}

            </div>
        </>
    )
}