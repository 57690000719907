// store.js
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';

// You would define your reducers like so:
const networkReducer = (state = {}, action) => {
  switch(action.type) {
    case 'UPDATECHAINID':
      return { ...state, chainId: action.chainId };
    case 'DELETECHAINCHAINID':
      return { ...state, chainId: null };
    default:
      return state;
  }
};

const userReducer = (state = {}, action) => {
    switch(action.type) {
      case 'LOGIN':
        return { ...state, userAuth: action.userAuth };
      case 'LOGOUT':
        return { ...state, userAuth: null };
      default:
        return state;
    }
  };

const rootReducer = combineReducers({
  network: networkReducer,
  user: userReducer
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['network','user'] // only persist user reducer
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
  persistedReducer,
  applyMiddleware(thunk)
);

export const persistor = persistStore(store);
