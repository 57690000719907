// src/components/Navigation.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import TableChartIcon from '@mui/icons-material/TableChart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Paper, } from '@mui/material';
import { useSelector } from 'react-redux';

const Navigation = () => {

    const userAuth = useSelector((state) => {
        if (state.user.userAuth) {
            return state.user.userAuth
        } else {
            return null;
        }
    })

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (userAuth) {
        return (

            <Paper style={{ background: "#fff", zIndex: 1000 }} sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    value={value}
                    onChange={handleChange}
                    showLabels
                    style={{ background: "#fff" }} // Set the background color to black
                >
                    <BottomNavigationAction label="Home" icon={<HomeIcon />} component={Link} to="/" />
                    <BottomNavigationAction label="Trades" icon={<TableChartIcon />} component={Link} to="/trades" />
                    <BottomNavigationAction label="Wallets" icon={<AccountBalanceWalletIcon />} component={Link} to="/wallets" />
                </BottomNavigation>
            </Paper>

        );
    }
};

export default Navigation;
