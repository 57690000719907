import React, { useState, useEffect, useRef } from "react";
import { Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Web3 from 'web3'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function Login() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const chainId = useSelector((state) => state.network.chainId);
    const [refreshing, setRefreshing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [feedback, setFeedback] = useState('');

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const processLogin = async () => {

        setIsLoading(true);

        const body = { username: username, password: password };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/processLogin`, {
            method: 'post',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' }
        });

        const data = await response.json();
        console.log(data)

        if (data.feedback == "not_found") {

            setIsLoading(false);
            toast.error("User not found", {
                position: "top-center"
            })
            return;

        } else if (data.feedback == "wrong_pass") {

            setIsLoading(false);
            toast.error("Wrong password", {
                position: "top-center"
            })
            return;

        } else if (data.feedback == "success") {

            toast.success("Login succesfull", {
                position: "top-center"
            })

            dispatch({ type: 'LOGIN', userAuth: { username: username, loggedIn: true, userId: data.data.userId, accessToken: data.data.accessToken } });

            navigate('/');

            return;

        } else {
            setIsLoading(false);
            toast.error("An unknown error occurred, please contact admin", {
                position: "top-center"
            })
            return;
        }

        setIsLoading(false);

    }

    return (
        <>
            <div style={{ marginBottom: 100, boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', padding: 10, margin: 20 }}>

                <br />
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        {/* Column 1 content */}
                        <div>
                            Client ID : <TextField
                                label=""
                                id="outlined-size-small"
                                size="small"
                                value={username}
                                onChange={(event) => setUsername(event.target.value)}
                            />
                        </div>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        {/* Column 1 content */}
                        <div>
                            <span style={{ marginTop: 20 }}>Password : </span> <TextField
                                label=""
                                id="outlined-size-small"
                                size="small"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}

                            />
                        </div>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button disabled={isLoading} onClick={()=>{
                            processLogin();
                        }} variant="contained" >{isLoading ? "Loading..." : "Login"} </Button>
                    </Grid>
                </Grid>

            </div>
            <ToastContainer />
        </>
    )
}